import React, { useState, useEffect } from 'react'
import { useRouteLoaderData, Link } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverDetails }  from '../../components/popoverDetails/popoverDetails';
import { PopoverMenu }  from '../../components/popoverMenu/popoverMenu';
import { Community }  from '../../models/communityClass';
import eventBus from "../../services/eventBus";
import { useLogging, useTourManager, usePic } from '../../services/servicesContext';

export function SharedVisitsCommunityComponent({ api, npis, notify, Taxonomy, download, /*community*/ CommunityList}) {
    
    const [ data, setData ] = useState(null);
    const [ loading, setLoading ] = useState(null);
    const [ expandedData, setExpandedData] = useState([]);
    const [ groupedData, setGroupedData] = useState([]);
    const [ currentTab, setCurrentTab] = useState("1");
    const community = useRouteLoaderData('community.cid');
    const tourManager = useTourManager();
    const logging = useLogging();
    const pic = usePic();

    useEffect(() => {

        logging.routeLoad({
            pathname: location.pathname,
            npis: [],
            statename: `root.app.com.reports.sharedvisits`
        });

        tourManager.createTour([{
            id: 1,
            title: 'Shared Visits Report - 1/14',
            text: "This report shows where patients end up within 30 days before/after an acute care episode, helping you understand where your Community can develop mutually beneficial relationships with pre- and post-acute care facilities and physicians.",
            beforeShowPromise: function() {
                return new Promise((resolve, reject) => {
                    var elementToClick = document.getElementById('1');
                    elementToClick.click();
                    setTimeout(()=>{
                        resolve();
                    });
                });
            }
        },{
            id: 2,
            title: 'Shared Visits Report - 2/14',
            attachTo: { element:".tourscript-name", on: 'bottom'},
            text: "For example, this provider saw..."
        },{
            id: 3,
            title: 'Shared Visits Report - 3/14',
            attachTo: { element: '.tourscript-uniquefrom', on: 'bottom' },
            text: "this many patients..."
        },{
            id: 4,
            title: 'Shared Visits Report - 4/14',
            text: "AFTER this Community's members"
        },{
            id: 5,
            title: 'Shared Visits Report - 5/14',
            attachTo: { element: '.tourscript-sharedfrom', on: 'bottom' },
            text: "which equaled this many total visits..."
        },{
            id: 6,
            title: 'Shared Visits Report - 6/14',
            attachTo: { element: '.tourscript-sameday', on: 'bottom' },
            text: "and this many same day visits."
        },{
            id: 7,
            title: 'Shared Visits Report - 7/14',
            attachTo: { element: '.tourscript-uniqueto', on: 'bottom' },
            text: "And this many patients..."
        },{
            id: 8,
            title: 'Shared Visits Report - 8/14',
            text: "BEOFRE this Community's members"
        },{
            id: 9,
            title: 'Shared Visits Report - 9/14',
            attachTo: { element: '.tourscript-sharedto', on: 'bottom' }, 
            text: "which equaled this many total visits..."
        },{
            id: 10,
            title: 'Shared Visits Report - 10/14',
            attachTo: { element: ".content-container thead", on: 'bottom' },
            text: "You can sort on any column by simply clicking on the header name."
        },{
            id: 11,
            title: 'Shared Visits Report - 11/14',
            attachTo: { element: ".content-container .table-controls-input .filter-box", on: 'bottom' },
            text: "Also, you can filter results here by searching for any value in the row."
        },{
            id: 12,
            title: 'Shared Visits Report - 13/14',
            attachTo: { element: ".content-container .table-controls-input #tourscript-buttons", on: 'bottom' },
            text: "Use this button to download the data in the table."
        },{
            id: 13,
            title: 'Shared Visits Report - 13/14',
            attachTo: { element: '.groupedTab', on: 'bottom'},
            text: 'Use the tabs to move between reports.'
        },{
            id: 14,
            title: 'Shared Visits Report - 14/14',
            text: 'There is no tour available for the Expanded Shared Visits Report yet.'
        }]);

        function init() {    
            setLoading(true);
            getAndFormatGroupedData((community), () => {
                setLoading(false);
                setCurrentTab(1);
            });

            getAndFormatExpandedData(community);
            
            eventBus.dispatch("recentlySelectedChangeCommunities");

        }    
        
        init();

        return () => {
            tourManager.clearTour();
        };
        
    }, [community]);


    const gotoTab = (e) => {
        setCurrentTab(e.target.id)
        eventBus.dispatch("recentlySelectedChangeCommunities");
    }

    function getTaxonomy(data) {
        var Taxonomy
        if (data.specialization) {
            Taxonomy=`${data.classification} (${data.specialization})`;
        } else if (data.classification) {
            Taxonomy=data.classification;
        } else {
            Taxonomy=data.type;
        }

        return Taxonomy
    }

    function getAndFormatExpandedData(community) {
        fetch('/api/community/' + community.id + '/sharedvisits/', api.options())
        .then(res => res.json())
        .then(function(res) {
            
            const dataVar = res.map(function(datum) {
                return {
                    'memberNpi': datum.member_npi,
                    'memberName': datum.member_name,
                    'memberTaxonomy': datum.member_specialization ? datum.member_classification + ' (' + datum.member_specialization + ')' : datum.member_classification,
                    'memberType': (datum.member_type == 'physician' ? 1 : (datum.member_type == 'organization' ? 2 : "")),
                    'memberTypeName': `${datum.member_type.charAt(0).toUpperCase()}${datum.member_type.slice(1)}`,
                    'memberAddress': [(datum.member_address1 || ''), (datum.member_address2 || '')],
                    'memberCity': datum.member_city,
                    'memberState': datum.member_state,
                    'memberCounty': datum.member_county,
                    'memberPostalCode': datum.member_postalcode,
                    'memberPhone': datum.member_phonenumber,
                    'npi': datum.npi,
                    'name': datum.provider,
                    'taxonomy': datum.specialization ? datum.classification + ' (' + datum.specialization + ')' : (datum.classification ? datum.classification : datum.type),/*new getTaxonomy(datum),*/
                    'type': datum.taxonomytype,
                    'address': [(datum.address1 || ''), (datum.address2 || '')],
                    'city': datum.city,
                    'state': datum.state,
                    'county': datum.county,
                    'postalcode': datum.postalcode,
                    'phone': datum.phonenumber,
                    'sharedFrom': datum.fromsharedtransactioncount,
                    'uniqueFrom': datum.frompatienttotal,
                    'sameday': datum.samedaytotal,
                    'uniqueTo': datum.topatienttotal,
                    'sharedTo': datum.tosharedtransactioncount,
                    'entitytypeName': `${datum.entitytype.charAt(0).toUpperCase()}${datum.entitytype.slice(1)}`,
                    'entitytype': (datum.entitytype == 'physician' ? 1 : (datum.entitytype == 'organization' ? 2 : ""))
                };
            })
            

            setLoading(false);
            setExpandedData(dataVar);

        }, function(err){
            console.log('Error fetching Shared Visits expanded report data: ', err);
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Shared Visits expanded report data.',
                delay: 30000
            });
        });
    }

    const reportConfigExpanded = function() { 
        return {  
          tableConfig :  [
                {
                    "header": {
                        "id": "col1expanded",
                        "accessor": "memberNpi",
                        "content": "Member NPI",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        'tooltipType': 'details',
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowDataObject) { 
                            const formatPhoneNum = rowDataObject && rowDataObject.memberPhone && rowDataObject.memberPhone.toString() || '';
                            const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            const provider = { 
                                location : {
                                'postal_code' : rowDataObject.memberPostalCode,
                                'phone_number': phone,
                                'city': rowDataObject.memberCity,
                                'state': rowDataObject.memberState
                                },
                                'name': {'display' : rowDataObject.memberName }
                            } 
                            return <PopoverDetails provider={provider}><Link to={`/physician/${content}/home`}>{content}</Link></PopoverDetails>  
                        }  
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 1                  
                    }
                },
                {
                    "header": {
                        "id": "col2expanded",
                        "accessor": "memberName",
                        "content": "Community Member",
                        "headerTooltip": "Row Community Member",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowObject) { 
                            const provider = {  name : content, npi : rowObject.npi, entitytype: rowObject.memberType };
                            return <>
                                <PopoverMenu provider={provider}>
                                    <span className='tourscript-name'>{content}</span>
                                    <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </span>
                                </PopoverMenu>
                            </> 
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                    "header": {
                        "id": "col3expanded",
                        "accessor": "memberTaxonomy",
                        "content": "Member Specialty",
                        "headerTooltip": "Row Member Specialty",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                },
                {
                    "header": {
                        "id": "col4expanded",
                        "accessor": "npi",
                        "content": "Provider NPI",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        'tooltipType': 'details',
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowDataObject) { 
                            const formatPhoneNum = rowDataObject && rowDataObject.phone && rowDataObject.phone.toString() || '';
                            const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            const provider = { 
                                location : {
                                  'postal_code' : rowDataObject.postalcode,
                                  'phone_number': phone,
                                  'city': rowDataObject.city,
                                  'state': rowDataObject.state
                                 },
                                'name': {'display' : rowDataObject.name }
                              } 
                            return <PopoverDetails provider={provider}><Link to={`/organization/${content}/home`}>{content}</Link></PopoverDetails>  
                        }  
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 4                   
                    }
                },
                {
                    "header": {
                        "id": "col5expanded",
                        "accessor": "name",
                        "content": "Provider",
                        "headerTooltip": "Row Provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowObject) { 
                            const provider = {  name : content, npi : rowObject.npi, entitytype: rowObject.entitytype };
                            return <>
                                <PopoverMenu provider={provider}>
                                    <span className='tourscript-name'>{content}</span>
                                    <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </span>
                                </PopoverMenu>
                            </> 
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                        }
                },
                {
                    "header": {
                        "id": "col6expanded",
                        "accessor": "taxonomy",
                        "content": "Provider Specialty",
                        "headerTooltip": "Row Provider Specialty",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                },
                {
                    "header": {
                        "id": "col7expanded",
                        "accessor": "sharedFrom",
                        "content": "Shared Visits From",
                        "headerTooltip": "Total visits to the row provider from report community",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content && content.toLocaleString()
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 7
                        }
                },
                {
                    "header": {
                        "id": "col8expanded",
                        "accessor": "uniqueFrom",
                        "content": "Unique Patients From",
                        "headerTooltip": "Unique patients to the Provider from the report community",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => <span className="tourscript-uniquefrom">{content && content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 8
                        }
                },
                {
                    "header": {
                        "id": "col9expanded",
                        "accessor": "sameday",
                        "content": "Same Day Visits",
                        "headerTooltip": "Same day visits for the Provider and report community",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "sort": 'desc',
                        "template": content => content && content.toLocaleString()
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 9
                    }
                },   
                {
                    "header": {
                        "id": "col10expanded",
                        "accessor": "uniqueTo",
                        "content": "Unique Patients To",
                        "headerTooltip": "Unique patients from the Provider to the report community",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => <span className='tourscript-uniqueto'>{content && content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 10
                        }
                },
                {
                    "header": {
                        "id": "col11expanded",
                        "accessor": "sharedTo",
                        "content": "Shared Visits To",
                        "headerTooltip": "Total visits from the Provider to report provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content && content.toLocaleString()
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 11
                        }
                },
                {
                    "header": {
                        "id": "col12expanded",
                        "accessor": "memberTypeName",
                        "content": "Member Type",
                        "headerTooltip": "Row Member Type",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 12
                        }
                },
                {
                    "header": {
                        "id": "col13expanded",
                        "accessor": "memberCity",
                        "content": "Member City",
                        "headerTooltip": "Row Member City",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 13
                        }
                },
                {
                    "header": {
                        "id": "col14expanded",
                        "accessor": "memberState",
                        "content": "Member State",
                        "headerTooltip": "Row Member State",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 14
                        }
                },
                {
                    "header": {
                        "id": "col15expanded",
                        "accessor": "memberPhone",
                        "content": "Member Phone",
                        "headerTooltip": "Row Member Phone number",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": function(content) { const formatPhoneNum = content.toString();
                            return <span className="text-nowrap">({formatPhoneNum.substring(0, 3)}) {formatPhoneNum.substring(3, 6)}-{formatPhoneNum.substring(6, 10)}</span> 
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 15
                        }
                },
                {
                    "header": {
                        "id": "col16expanded",
                        "accessor": "type",
                        "content": "Provider Taxonomy Type",
                        "headerTooltip": "Row provider taxonomy type",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 16
                        }
                },
                {
                    "header": {
                        "id": "col17expanded",
                        "accessor": "city",
                        "content": "City",
                        "headerTooltip": "Row provider city",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 17
                        }
                },
                {
                    "header": {
                        "id": "col18expanded",
                        "accessor": "state",
                        "content": "State",
                        "headerTooltip": "Row provider state",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 18
                        }
                },
                {
                    "header": {
                        "id": "col19expanded",
                        "accessor": "phone",
                        "content": "Phone",
                        "headerTooltip": "Row provider phone number",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": function(content) { const formatPhoneNum = content.toString();
                            return <span className="text-nowrap">({formatPhoneNum.substring(0, 3)}) {formatPhoneNum.substring(3, 6)}-{formatPhoneNum.substring(6, 10)}</span> 
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 19
                        }
                },
                {
                    "header": {
                        "id": "col20expanded",
                        "accessor": "entitytypeName",
                        "content": "Entity Type",
                        "headerTooltip": "Row provider entity type",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 20
                        }
                    },
                    {
                        "header": {
                            "id": "col21expanded",
                            "accessor": "memberPostalCode",
                            "content": "Member ZIP",
                            "headerTooltip": "Row Member ZIP",
                            "defaultVisibilty": false,
                            "export": false,
                            "sortable": true,
                            "template": content => content
                        },
                        "column": {
                                "class": "'text-center'",
                                "content": {},
                                "style": "",
                                "id": 21
                            }
                    },
                    {
                        "header": {
                            "id": "col22expanded",
                            "accessor": "postalcode",
                            "content": "ZIP",
                            "headerTooltip": "Row provider postal code",
                            "defaultVisibilty": false,
                            "export": false,
                            "sortable": true,
                            "template": content => content
                        },
                        "column": {
                                "class": "'text-center'",
                                "content": {},
                                "style": "",
                                "id": 22
                            }
                    },
                    {
                        "header": {
                            "id": "col23expanded",
                            "accessor": "county",
                            "content": "Provider County",
                            "headerTooltip": "Row provider county",
                            "defaultVisibilty": false,
                            "export": false,
                            "sortable": true,
                            "template": content => content
                        },
                        "column": {
                                "class": "'text-center'",
                                "content": {},
                                "style": "",
                                "id": 23
                        }
                    },
                    {
                        "header": {
                            "id": "col24expanded",
                            "accessor": "memberCounty",
                            "content": "Member County",
                            "headerTooltip": "Community member county",
                            "defaultVisibilty": false,
                            "export": false,
                            "sortable": true,
                            "template": content => content
                        },
                        "column": {
                                "class": "'text-center'",
                                "content": {},
                                "style": "",
                                "id": 24
                        }
                    },
                    {
                        "header": {
                            "id": "col25expanded",
                            "accessor": "memberAddress",
                            "content": "Member Address",
                            "headerTooltip": "Community member address",
                            "defaultVisibilty": false,
                            "export": false,
                            "sortable": true,
                            "template": content => content
                        },
                        "column": {
                                "class": "'text-center'",
                                "content": {},
                                "style": "",
                                "id": 25
                        }
                    },
                    {
                        "header": {
                            "id": "col26expanded",
                            "accessor": "address",
                            "content": "Provider Address",
                            "headerTooltip": "Provider address",
                            "defaultVisibilty": false,
                            "export": false,
                            "sortable": true,
                            "template": content => content
                        },
                        "column": {
                                "class": "'text-center'",
                                "content": {},
                                "style": "",
                                "id": 26
                        }
                    },
               ],
                configure : true,
                configureButton: true,
                showSelected: false,
                filterInput: true,
                csvdownload: true,
                pagination: true,
                localStorageName: 'reports.sharedvisits.expanded.v-3',
                reportTitle: `Expanded Shared Visits Report for ${community && community.name}`,
                csvdDownloadTemplate: function() { return null }
                }
    }

    function getAndFormatGroupedData(community) {
        fetch('/api/community/' + community.id + '/sharedvisits/', api.options())
        .then(res => res.json())
        .then(function(res) {
            
            var data = preFormatGroupedData(res);
            
            const groupedData = data.map(function(datum) {
                    var list = datum.list.map(function(listDatum) {
                        return {
                            'npi': listDatum.member_npi,
                            'communities': pic(listDatum.member_npi),
                            'name': listDatum.member_name,
                            'taxonomy': listDatum.member_specialization ? listDatum.member_classification + ' (' + listDatum.member_specialization + ')' : listDatum.member_classification,
                            'sharedFrom': listDatum.fromsharedtransactioncount,
                            'uniqueFrom': listDatum.frompatienttotal,
                            'sameday': listDatum.samedaytotal,
                            'uniqueTo': listDatum.topatienttotal,
                            'sharedTo': listDatum.tosharedtransactioncount,
                            'entitytypeName': `${listDatum.member_type.charAt(0).toUpperCase()}${listDatum.member_type.slice(1)}`,
                            'entitytype': (listDatum.member_type == 'physician' ? 1 : (listDatum.member_type == 'organization' ? 2 : "")),
                            'address': [(listDatum.member_address1 || ''), (listDatum.member_address2 || '')],
                            'city': listDatum.member_city,
                            'state': listDatum.member_state,
                            'county': listDatum.member_county,
                            'postalcode': listDatum.member_postalcode,
                            'phone': listDatum.member_phonenumber
                        };
                    });
                    


                    return {
                        'npi': datum.npi,
                        'communities': pic(datum.npi),
                        'name': datum.provider,
                        'taxonomy': datum.specialization ? datum.classification + ' (' + datum.specialization + ')' : (datum.classification ? datum.classification : datum.type)/*new Taxonomy(datum)*/,
                        'sharedFrom': datum.fromsharedtransactioncount,
                        'uniqueFrom': datum.frompatienttotal,
                        'sameday': datum.samedaytotal,
                        'uniqueTo': datum.topatienttotal,
                        'sharedTo': datum.tosharedtransactioncount,
                        'entitytypeName': `${datum.entitytype.charAt(0).toUpperCase()}${datum.entitytype.slice(1)}`,
                        'entitytype': (datum.entitytype == 'physician' ? 1 : (datum.entitytype == 'organization' ? 2 : "")),
                        'address': datum.address,
                        'city': datum.city,
                        'state': datum.state,
                        'county': datum.county,
                        'postalcode': datum.postalcode,
                        'phone': datum.phonenumber,
                        'list': list
                    };
            });

            setLoading(false);
            setGroupedData(groupedData);

        }, function(err){
            console.log('Error fetching Shared Visits grouped report data: ', err);
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Shared Visits grouped report data.',
                delay: 30000
            });
        });
    }

    function preFormatGroupedData(data) {
        var providers = {};
        for (var d in data) {
            var datum = data[d];
            if (!(datum['npi'] in providers)) {
                providers[datum['npi']] = {
                    'npi': datum['npi'],
                    'provider': datum['provider'],
                    'address': [(datum['address1'] || ''), (datum['address2'] || '')],
                    'city': datum['city'],
                    'state': datum['state'],
                    'county': datum['county'],
                    'postalcode': datum['postalcode'],
                    'phonenumber': datum['phonenumber'],
                    'entitytype': datum['entitytype'],
                    'classification': datum['classification'],
                    'specialization': datum['specialization'],
                    'topatienttotal': datum['topatienttotal'],
                    'tosharedtransactioncount': datum['tosharedtransactioncount'],
                    'samedaytotal': datum['samedaytotal'],
                    'frompatienttotal': datum['frompatienttotal'],
                    'fromsharedtransactioncount': datum['fromsharedtransactioncount']
                };
                providers[datum['npi']]['list'] = [];
            } else {
                providers[datum['npi']]['tosharedtransactioncount'] += datum['tosharedtransactioncount'];
                providers[datum['npi']]['samedaytotal'] += datum['samedaytotal'];
                providers[datum['npi']]['frompatienttotal'] += datum['frompatienttotal'];
                providers[datum['npi']]['topatienttotal'] += datum['topatienttotal'];
                providers[datum['npi']]['fromsharedtransactioncount'] += datum['fromsharedtransactioncount'];
            }
            providers[datum['npi']]['list'].push(datum);
        }
        
        var providersArr = Object.keys(providers).map(function(d) {
            return providers[d];
        });
        return providersArr;
    }

    const reportConfigGrouped = function() { 
        return {  
          tableConfig :  [
                {
                    "header": {
                        "id": "col0",
                        "accessor": "show",
                        "content": "Show",
                        "defaultVisibilty": true,
                        "type" : "collapse-button",
                        "collasped" : true,
                        "classLists" : "fa fa-chevron-right",
                        "sorting": false,
                        "exclude": true,
                        "template": function() { return }   
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 0                   
                    }
                },
                {
                    "header": {
                        "id": "col1grouped",
                        "accessor": "npi",
                        "content": "NPI",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        'tooltipType': 'details',
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowDataObject) { 
                            const formatPhoneNum = rowDataObject && rowDataObject.phone && rowDataObject.phone.toString() || '';
                            const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            const provider = { 
                                location : {
                                  'postal_code' : rowDataObject.postalcode,
                                  'phone_number': phone,
                                  'city': rowDataObject.city,
                                  'state': rowDataObject.state
                                 },
                                'name': {'display' : rowDataObject.name }
                              } 
                            return <PopoverDetails provider={provider}><Link to={`/${rowDataObject.entitytype == 1 ? 'physician' : 'organization'}/${content}/home`}>{content}</Link></PopoverDetails>  
                        }  
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 1                  
                    }
                },
                {
                    "header": {
                        "id": "col2grouped",
                        "accessor": "communities",
                        "content": "Communities",
                        "headerTooltip": "Row Community",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content) {
                            if (content && content.length === 0) return                   
                            const arrContent = content.map((item) => {
                                const { name, insert_date, owner, npis } = item;
                                const providerCollection = {
                                    name: name || "",
                                    insert_date: insert_date && new Date(Number(insert_date)).toDateString() || "",
                                    insert_user: owner || "",
                                    npis: npis || ""
                                }
                                return (<PopoverDetails providerCollection={providerCollection}><Link to={`/community/${item.id}/home`}>{item.abbr} </Link></PopoverDetails>)  
                            }) 
                            return arrContent;
                       }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                    "header": {
                        "id": "col3grouped",
                        "accessor": "name",
                        "content": "Provider",
                        "headerTooltip": "Row Provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowObject) { 
                            const provider = {  name : content, npi : rowObject.npi, entitytype: rowObject.entitytype };
                            return <>
                                <PopoverMenu provider={provider}>
                                    <span className='tourscript-name'>{content}</span>
                                    <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </span>
                                </PopoverMenu>
                            </> 
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                },
                {
                    "header": {
                        "id": "col4grouped",
                        "accessor": "taxonomy",
                        "content": "Specialty",
                        "headerTooltip": "Row Provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                },
                {
                    "header": {
                        "id": "col5grouped",
                        "accessor": "sharedFrom",
                        "content": "Shared Visits From",
                        "headerTooltip": "Total visits to the row provider from report community",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => <span className="tourscript-sharedfrom">{content && content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                        }
                },
                {
                    "header": {
                        "id": "col6grouped",
                        "accessor": "uniqueFrom",
                        "content": "Unique Patients From",
                        "headerTooltip": "Unique patients to the Provider from the report community",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => <span className="tourscript-uniquefrom">{content && content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                },
                {
                    "header": {
                        "id": "col7grouped",
                        "accessor": "sameday",
                        "content": "Same Day Visits",
                        "headerTooltip": "Same day visits for the Provider and report community",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => <span className="tourscript-sameday">{content && content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 7
                    }
                },   
                {
                    "header": {
                        "id": "col8grouped",
                        "accessor": "uniqueTo",
                        "content": "Unique Patients To",
                        "headerTooltip": "Unique patients from the Provider to the report community",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "sort": 'desc',
                        "template": content => <span className='tourscript-uniqueto'>{content && content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 8
                        }
                },
                {
                    "header": {
                        "id": "col9grouped",
                        "accessor": "sharedTo",
                        "content": "Shared Visits To",
                        "headerTooltip": "Total visits from the Provider to report provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "sort": 'desc',
                        "template": content => <span className="tourscript-sharedto">{content && content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 9
                        }
                },
                {
                    "header": {
                        "id": "col10grouped",
                        "accessor": "entitytypeName",
                        "content": "Entity Type",
                        "headerTooltip": "Row Entity Type",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 10
                        }
                },
                {
                    "header": {
                        "id": "col11grouped",
                        "accessor": "city",
                        "content": "City",
                        "headerTooltip": "Row Member City",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 11
                        }
                },
                {
                    "header": {
                        "id": "col12grouped",
                        "accessor": "state",
                        "content": "State",
                        "headerTooltip": "Row Member State",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 12
                        }
                },
                {
                    "header": {
                        "id": "col13grouped",
                        "accessor": "phone",
                        "content": "Phone",
                        "headerTooltip": "Row Member Phone number",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": function(content) { const formatPhoneNum = content.toString();
                            return <span className="text-nowrap">({formatPhoneNum.substring(0, 3)}) {formatPhoneNum.substring(3, 6)}-{formatPhoneNum.substring(6, 10)}</span> 
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 13
                        }
                },
                {
                    "header": {
                        "id": "col14grouped",
                        "accessor": "postalcode",
                        "content": "ZIP",
                        "headerTooltip": "Row Member ZIP",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 14
                        }
                },
                {
                    "header": {
                        "id": "col15grouped",
                        "accessor": "county",
                        "content": "County",
                        "headerTooltip": "Row County",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 15
                        }
                },
                {
                    "header": {
                        "id": "col16grouped",
                        "accessor": "address",
                        "content": "Address",
                        "headerTooltip": "Row Address",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 16
                    }
                },
               ],
                configure : true,
                configureButton: true,
                showSelected: false,
                filterInput: true,
                csvdownload: true,
                pagination: true,
                nested: "list",
                localStorageName: 'reports.sharedvisits.grouped.v-3',
                reportTitle: `Grouped Shared Visits Report for ${community && community.name}`,
                csvdDownloadTemplate: function() { return null }
                }
    }

    const renderTabs = () => {
        if (currentTab === "1") return groupedData ? <PhtableComponent data={groupedData} reportconfig={reportConfigGrouped} download={download} needCommUpdate={true} CommunityList={CommunityList}/> : "No data found"
        if (currentTab === "2") return expandedData ? <PhtableComponent data={expandedData} reportconfig={reportConfigExpanded} download={download} needCommUpdate={true} CommunityList={CommunityList}/> : "No data found"   
    }

    return (
        <>
        <ul className="nav nav-tabs">
           <li className={currentTab === "1" ? "active groupedTab" : "groupedTab"}><a id="1" onClick={(e) => gotoTab(e)}>Grouped Report</a></li>
           <li className={currentTab === "2" ? "active expandedTab" : "expandedTab"}><a id="2" onClick={(e) => gotoTab(e)}>Expanded Report</a></li>
        </ul>
         <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
           {renderTabs()}
         </>
    )
}