import React, { useEffect, useState, useMemo } from 'react';

import { useConfig, useApi, usePic, useAuth, useTourManager, useLogging } from '../../services/servicesContext';

import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverMenu }  from '../../components/popoverMenu/popoverMenu';
import { Link } from "react-router-dom";

export function GroupsComponent(props) {
    
    const pic = usePic();
    const api = useApi();
    const tourManager = useTourManager();
    const logging = useLogging();
    const [ currentTab, setCurrentTab ] = useState('my');
    const [ communities, setCommunities ] = useState(null);
    const [ originalCommunities, setOriginalCommunities ] = useState({});
    const [ activeCommunityId, setActiveCommunityId ] = useState(null);
    const [ updateSharing, setUpdateSharing ] = useState(false);
    const [ updateWatching, setUpdateWatching ] = useState(false);
    const [ updateCopying, setUpdateCopying ] = useState(false);

    const myCommunities = useMemo(() => {
        if (communities) {
            return communities.filter(function(community) {
                return community.owned;
            });
        }
        return [];
    }, [communities]);

    const sharedCommunities = useMemo(() => {
        if (communities) {
            return communities.filter(function(community) {
                return community.is_public;
            });
        }
        return [];
    }, [communities]);

    useEffect(() => {

        tourManager.createTour([
            {
                id: 1,
                title: 'Communities - 1/12',
                text: "All the Communities you, or someone in your account have created, will be available here.",
                before: function(){
                    return new Promise((resolve, reject) => {
                        var elementToClick = document.querySelector('groups ul.nav-tabs li:nth-child(1) a.nav-link');
                        var clickEvent = new Event('click');
                        elementToClick.dispatchEvent(clickEvent);
                        setTimeout(() => {
                            resolve();
                        });
                    });
                }
            },{
                id: 2,
                attachTo: { element: '.tourscript-my-com-name', on: 'bottom' },
                title: 'Communities - 2/12',
                text: "Each Community will be listed by its Name and its three-character abbreviation."
            },{
                id: 3,
                attachTo: { element: '.tourscript-my-com-remove-edit', on: 'bottom' },
                title: 'Communities - 3/12',
                text: "If you are the owner of the Community, you can delete or edit it here."
            },{
                id: 4,
                attachTo: { element: '.tourscript-my-com-size', on: 'bottom' },
                title: 'Communities - 4/12',
                text: "The size (number of clinical entities included) is listed here."
            },{
                id: 5,
                attachTo: { element: '.tourscript-my-com-type', on: 'bottom' },
                title: 'Communities - 5/12',
                text: `The type of Community is listed here. If the Community only contains physicians the type will be labeled as "Physicians", if it contains only organizations it will be labeled as "Organizations", if it contains physicians and organizations it will be labeled "Hybrid."`
            },{
                id: 6,
                attachTo: { element: '.tourscript-my-com-share', on: 'bottom' },
                title: 'Communities - 6/12',
                text: `If you want to share a Community click here, this will make the Community available to everyone in your account via the "Shared Communities" tab. If you are currently sharing the Community, this button will be green.`
            },{
                id: 7,
                attachTo: { element: '.tourscript-my-com-watch', on: 'bottom' },
                title: 'Communities - 7/12',
                text: `If you want to track this Community on reports through the rest of the system you can click the "Watch" icon. This will display the three-letter abbreviation next to any member of the Community when they appear on other reports and graphics. If you are currently watching a Community this icon will appear green.`
            },{
                id: 8,
                attachTo: { element: '#sharedCommunitiesTab', on: 'bottom' },
                title: 'Communities - 8/12',
                text: `If members of your account are sharing their created communities, you can access them from the "Shared Communities" tab.`
            },{
                id: 9,
                attachTo: { element: '.tourscript-ownercolumn', on: 'bottom'},
                title: 'Communities - 9/12',
                text: `Here you can view communities shared with your account`,
                beforeShowPromise: function() {
                    return new Promise((resolve, reject) => {
                        document.querySelector('.shared-communities-tab-link').click();
                        setTimeout(() => {
                            resolve();
                        });
                    });
                }
            },{
                id: 10,
                attachTo: { element: '.tourscript-ownercolumn', on: 'bottom'},
                title: 'Communities - 10/12',
                text: `Each community's owner is indicated`
            },{
                id: 11,
                attachTo: { element: '.tourscript-shared-copy-button', on: 'bottom'},
                title: 'Communities - 11/12',
                text: `You can copy a community and it will show up in your communities`
            },{
                id: 12,
                attachTo: { element: '.tourscript-shared-watch-button', on: 'bottom'},
                title: 'Communities - 12/12',
                text: `Like in your communities, if you want to track this Community on reports through the rest of the system you can click the "Watch" icon. This will display the three-letter abbreviation next to any member of the Community when they appear on other reports and graphics. If you are currently watching a Community this icon will appear green.`
            }
        ]);
        logging.routeLoad({
            pathname: location.pathname,
            npis: [],
            statename: 'root.app.groups'
        });
        async function init() {
            try {
                if (pic.ready) {
                    await pic.Update();
                    await updateList();
                }
            } catch (err) {
                console.error('error initializing', err);
            }
        }
        
        init();

        return () => {
            tourManager.clearTour();
        };

    }, [pic.ready]);

    function removeDeletedItem(deletedCommunity) {
        setOriginalCommunities(prev => {
            let communitiesToSet = {...prev};
            delete communitiesToSet[deletedCommunity.id];
            return communitiesToSet;
        });
        
        setCommunities(() => {
            return communities.filter(function(community) {
                return community.id !== deletedCommunity.id;
            });
        });
    }


    async function getformattedCommunities() {
        
        let originalCommunitiesToSet = {};

        let communities = pic();
        communities.sort((a, b) => {
            return b.insert_date - a.insert_date;
        });

        // "prime" the provider cache so multiple calls to community.type() dont have promises that stay in pending state
        const uniqueCommunitiesProviders = await Promise.all(
            [...new Set(communities.map(c => c.npis).flat())]
            .map(npi => api.GetProvider(npi))
        );

        const communityPromises = communities.map(community => {
            originalCommunitiesToSet[community.id] = community;
            return community.type(api).then(comType => {
                return {
                    'name': community.name,
                    'abbr': community.abbr,
                    'id': community.id,
                    'count': community.count,
                    'insert_date': community.insert_date,
                    'owner': community.owner,
                    'type': getTypeString(comType),
                    'is_public': community.is_public,
                    'watched': community.watched,
                    'owned': community.owned
                };
            });
        });
        
        const comms = await Promise.all(communityPromises);
        setOriginalCommunities(originalCommunitiesToSet);
        return comms;
       
    }

    function updateList() {
        return getformattedCommunities()
        .then(formattedCommunities => {
            setCommunities(formattedCommunities);
            return true;
        }, err => {
            console.error('error getting formattedCommunities', err);
            return Promise.reject(err);
        });
    }

    function handleConfirmDelete(community) {
        community.Delete({
            api,
            removeDeletedItem,
            updateList,
            pic
        });
    }

    function handleEdit(community) {
        community.EditName({
            updateList,
            pic,
            api
        });
    }

    function handleShare(community, id) {
        setActiveCommunityId(id);
        setUpdateSharing(true);
        community.Update({is_public: !community.is_public}, {api, pic, notify: props.notify})
        .then(function() {
            return updateList();
        })
        .then(() => {
            setUpdateSharing(false);
        });
    }

    function handleWatch(community, id) {
        setActiveCommunityId(id);
        setUpdateWatching(true);
        community.Update({watched: !community.watched}, {api, pic, notify: props.notify})
        .then(function() {
            return updateList();
        })
        .then(() => {
            setUpdateWatching(false);
        });
    }

    function handleCopy(community, id) {
        setActiveCommunityId(id);
        setUpdateCopying(true);
        community.Copy({api, pic, notify: props.notify})
        .then(function() {
            return updateList();
        })
        .then(() => {
            setUpdateCopying(false);
        });
    };

    const myCommunitiesConfig = function() {
        return {
            configure: true,
            configureButton: false,
            showSelected: false,
            filterInput: true,
            csvdownload: true,
            pagination: true,
            reportTitle: `My Communities`,
            tableConfig: [
                {
                    header: {
                        id: 'col0a',
                        accessor: '',
                        content: 'Remove/Edit',
                        defaultVisibilty: true,
                        export: false,
                        sortable: false,
                        template: (content, rowDataObject) => {
                            return <span className='tourscript-my-com-remove-edit'>
                                <button className="btn btn-default btn-sm delete" onClick={e => handleConfirmDelete(originalCommunities[rowDataObject.id])}>
                                    <span><i className="fa fa-minus-circle fa-lg" aria-hidden="true"></i></span>
                                </button>
                                <button className="btn btn-default btn-sm edit" onClick={e => handleEdit(originalCommunities[rowDataObject.id])}>
                                    <span ><i className="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></span>
                                </button>
                            </span>;
                        }
                    }
                },
                {
                    header: {
                        id: 'col1a',
                        accessor: 'name',
                        content: 'Name',
                        defaultVisibilty: true,
                        export: true,
                        sortable: true,
                        template: (content, rowDataObject) => {
                            return <PopoverMenu providerCollection={rowDataObject}>
                                <Link className="word-wrap tourscript-my-com-name" to={`/community/${rowDataObject.id}/home`}>({rowDataObject.abbr}) {rowDataObject.name}</Link>
                                <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </span>
                            </PopoverMenu>;
                        }
                    }
                },
                {
                    header: {
                        id: 'col2a',
                        accessor: 'count',
                        content: 'Size',
                        defaultVisibilty: true,
                        export: true,
                        sortable: true,
                        template: content => <span className='tourscript-my-com-size'>{content}</span>
                    }
                },
                {
                    header: {
                        id: 'col3a',
                        accessor: 'insert_date',
                        content: 'Created',
                        defaultVisibilty: true,
                        export: true,
                        sortable: true,
                        template: content => {
                            let insertDate = new Date(content);
                            return insertDate.toLocaleDateString("en-US", {
                                weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
                            });
                        }
                    }
                },
                {
                    header: {
                        id: 'col4a',
                        accessor: 'type',
                        content: 'Type',
                        defaultVisibilty: true,
                        export: true,
                        sortable: true,
                        template: content => <span className='tourscript-my-com-type'>{content}</span>
                    }
                },
                {
                    header: {
                        id: 'col5a',
                        accessor: 'is_public',
                        content: 'Share',
                        defaultVisibilty: true,
                        export: false,
                        sortable: false,
                        template: (content, rowDataObject) => {
                            return <button className={`tourscript-my-com-share btn btn-default btn-sm ${ rowDataObject.is_public ? 'select-true': ''}`} onClick={e => handleShare(originalCommunities[rowDataObject.id], rowDataObject.id)}>
                                <span>
                                    { (updateSharing && activeCommunityId === rowDataObject.id)
                                        ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                        : <i className="fa fa-share-alt fa-lg" aria-hidden="true"></i>
                                    }
                                </span>
                            </button>;
                        }
                    }
                },
                {
                    header: {
                        id: 'col6a',
                        accessor: 'watched',
                        content: 'Watch',
                        defaultVisibilty: true,
                        export: true,
                        sortable: false,
                        template: (content, rowDataObject) => {
                            return <button className={`tourscript-my-com-watch btn btn-default btn-sm ${rowDataObject.watched ? 'select-true' : ''}`} onClick={e => handleWatch(originalCommunities[rowDataObject.id], rowDataObject.id)}>
                                <span>
                                    { (updateWatching && activeCommunityId === rowDataObject.id)
                                        ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                        : rowDataObject.watched
                                            ? <i className="fa fa-eye fa-lg" aria-hidden="true"></i>
                                            : <i className="fa fa-eye-slash fa-lg" aria-hidden="true"></i>
                                    }
                                </span>
                            </button>;
                        }
                    }
                }
            ]
        };
    };

    const sharedCommunitiesConfig = function() {
        return {
            configure: true,
            configureButton: false,
            showSelected: false,
            filterInput: true,
            csvdownload: true,
            pagination: true,
            reportTitle: `Shared Communities`,
            tableConfig: [
                {
                    header: {
                        id: 'col0b',
                        accessor: 'name',
                        content: 'Name',
                        defaultVisibilty: true,
                        export: true,
                        sortable: true,
                        template: (content, rowDataObject) => {
                            return <PopoverMenu providerCollection={rowDataObject}>
                            <Link className="word-wrap" to={`/community/${rowDataObject.id}/home`}>({rowDataObject.abbr}) {rowDataObject.name}</Link>
                                <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </span>
                            </PopoverMenu>;
                        }
                    }
                },
                {
                    header: {
                        id: 'col1b',
                        accessor: 'count',
                        content: 'Group Size',
                        defaultVisibilty: true,
                        export: true,
                        sortable: true,
                        template: content => content
                    }
                },
                {
                    header: {
                        id: 'col2b',
                        accessor: 'insert_date',
                        content: 'Created',
                        defaultVisibilty: true,
                        export: true,
                        sortable: true,
                        template: content => {
                            let insertDate = new Date(content);
                            return insertDate.toLocaleDateString("en-US", {
                                weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
                            });
                        }
                    }
                },
                {
                    header: {
                        id: 'col3b',
                        accessor: 'owner',
                        content: 'Owner',
                        defaultVisibilty: true,
                        export: true,
                        sortable: true,
                        template: content => <span className='tourscript-ownercolumn'>{content}</span>
                    }
                },
                {
                    header: {
                        id: 'col4b',
                        accessor: 'type',
                        content: 'Type',
                        defaultVisibilty: true,
                        export: true,
                        sortable: true,
                        template: content => content
                    }
                },
                {
                    header: {
                        id: 'col5b',
                        accessor: '',
                        content: 'Copy',
                        defaultVisibilty: true,
                        export: false,
                        sortable: false,
                        template: (content, rowDataObject) => {
                            return <button className="btn btn-default btn-sm tourscript-shared-copy-button" onClick={e => handleCopy(originalCommunities[rowDataObject.id], rowDataObject.id)}>
                                <span>
                                    { (updateCopying && activeCommunityId === rowDataObject.id)
                                        ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                        : <i className="fa fa-files-o fa-lg" aria-hidden="true"></i>
                                    }
                                </span>
                            </button>;
                        }
                    }
                },
                {
                    header: {
                        id: 'col6b',
                        accessor: 'watched',
                        content: 'Watch',
                        defaultVisibilty: true,
                        export: true,
                        sortable: false,
                        template: (content, rowDataObject) => {
                            return <button className={`btn btn-default btn-sm tourscript-shared-watch-button ${rowDataObject.watched ? 'select-true' : ''}`} onClick={e => handleWatch(originalCommunities[rowDataObject.id], rowDataObject.id)}>
                                <span>
                                    { (updateWatching && activeCommunityId === rowDataObject.id)
                                        ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                        : rowDataObject.watched
                                            ? <i className="fa fa-eye fa-lg" aria-hidden="true"></i>
                                            : <i className="fa fa-eye-slash fa-lg" aria-hidden="true"></i>
                                    }
                                </span>
                            </button>;
                        }
                    }
                }
            ]
        };
    };

    return <>
        <ul className="nav nav-tabs">
            <li id='myCommunitiesTab' className={currentTab === 'my' ? 'active' : ''}>
                <a className="my-communities-tab-link" onClick={e => setCurrentTab('my')}>My Communities</a>
            </li>
            <li id='sharedCommunitiesTab' className={currentTab === 'shared' ? 'active' : ''}>
                <a className="shared-communities-tab-link" onClick={e => setCurrentTab('shared')}>Shared Communities</a>
            </li>
        </ul>
        { (currentTab === 'my' && myCommunities && props.download && myCommunitiesConfig && myCommunities.length > 0) && <PhtableComponent data={myCommunities} download={props.download} reportconfig={myCommunitiesConfig} />}
        { (currentTab === 'shared' && sharedCommunities && props.download && sharedCommunities.length > 0) && <PhtableComponent data={sharedCommunities} download={props.download} reportconfig={sharedCommunitiesConfig} />}
    </>;
}

function getTypeString(type) {
    if (type == 1) {
        return 'Physicians';
    } else if (type == 2) {
        return 'Organizations';
    } else if (type == 0) {
        return 'Empty';
    } else {
        return 'Hybrid';
    }
}