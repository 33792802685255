import { NonRetryableError } from "@aws-amplify/core";
import React, { useState } from "react";
import { usePopper }  from 'react-popper';

import './popoverDetails.scss';

export function PopoverDetails(props) {
    
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const [showPopperElem, setShowPopperElem] = useState(false);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: props.placement ? props.placement : 'top',
        modifiers: [{ name: 'arrow', options: { element: arrowElement } }, {name: 'offset', options: {offset: [0,5]}}],
    });

    const popperElemStyles = {
        backgroundColor: 'white',
        boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
        borderRadius: '5px',
        marginBottom: 0,
        fontSize: '.8em'
    };

    const poppertooltipElemStyles = {
        backgroundColor: 'black',
        borderRadius: '5px',
        // marginBottom: '2px',
        fontSize: '0.8em',
        color: '#ffffff',
        // width: '200px',
        maxWidth: '200px',
        padding: '5px 10px',
        textAlign: 'center',
        border: 'none',
        zIndex: 1000
    }

    if (props.width) {
        poppertooltipElemStyles.width = props.width;
    }
    
    return (<>
        <span
            ref={setReferenceElement}
            onMouseEnter={() => setShowPopperElem(true)}
            onMouseLeave={() => setShowPopperElem(false)}
        >{ props.children }</span>
            { props.provider && showPopperElem ? <div ref={setPopperElement} id="tooltip" className="panel panel-default" style={{...styles.popper, ...popperElemStyles}} {...attributes.popper}>
                <div className="panel-heading">
                    <h5 style={{margin:0}}>
                        {props.provider.name.display}
                    </h5>
                </div>
                <table className="panel-body table table-striped">
                    <tbody>
                        <tr>
                            <th>City, State</th>
                            <td>{props.provider.location.city}, {props.provider.location.state}</td>
                        </tr>
                        <tr>
                            <th>Postal Code</th>
                            <td>{props.provider.location.postal_code}</td>
                        </tr>
                        <tr>
                            <th>Phone Number</th>
                            <td>{props.provider.location.phone_number}</td>
                        </tr>
                    </tbody>
                </table>
                <div id="arrow" ref={setArrowElement} data-popper-arrow style={{...styles.arrow}} {...attributes.arrow}/>
            </div> : null }
            { props.providerCollection && showPopperElem ? <div ref={setPopperElement} id="tooltip" className="panel panel-default" style={{...styles.popper, ...popperElemStyles}} {...attributes.popper}>
                <div className="panel-heading">
                    <h5 style={{margin:0}}>
                        {props.providerCollection.name}
                    </h5>
                </div>
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <th>Date Created</th>
                            <td>{props.providerCollection.insert_date.substring(0,16)}</td>
                        </tr>
                        <tr>
                            <th>Owner</th>
                            <td>{props.providerCollection.insert_user}</td>
                        </tr>
                        <tr>
                            <th>Members</th>
                            <td>{props.providerCollection.npis.length}</td>
                        </tr>
                    </tbody>
                </table>
                <div id="arrow" ref={setArrowElement} data-popper-arrow style={{...styles.arrow}} {...attributes.arrow}/>
            </div> : null}
            { props.providerTooltip && showPopperElem ? <div ref={setPopperElement} id="tooltip" className="panel panel-default" style={{...styles.popper, ...poppertooltipElemStyles}} {...attributes.popper}>
                <div>{props.providerTooltip}</div>
                <div id="arrow" ref={setArrowElement} data-popper-arrow style={{...styles.arrow}} {...attributes.arrow}/>
            </div> : null}
    </>);
}
