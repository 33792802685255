import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./modalCommunity.scss";
import { useApi, useCommunityList, usePic } from "../../services/servicesContext";
import { PopoverDetails } from "../popoverDetails/popoverDetails";
import Select from "react-select";
import notify from "../../services/notify";
import { v4 as uuid_v4 } from "uuid"
import { useNavigate } from "react-router-dom";

export function CommunityModal(props) {
    const CommunityList = useCommunityList();

    const {show, onClose, api} = props
    const [providersToAdd, setProvidersToAdd] = useState(null);
    const [number, setNumber] = useState(null);
    const [totalLength, setTotalLength] = useState(null);
    const [suggestedAbbr, setSuggestedAbbr] = useState('e.g. ABC');
    const [newCommunityMembers, setNewCommunityMembers] = useState([]);
    const [ownedGroups, setOwnedGroups] = useState(null);
    const [toggleList, setToggleList] = useState(false);
    const [action, setAction] = useState("Create");
    const [communityWatching, setCommunityWatching] = useState(true);
    const [communityIsPublic, setCommunityIsPublic] = useState(false);
    const [communityGoHome, setCommunityGoHome] = useState(false);
    const [communityName, setCommunityName] = useState(null);
    const [communityAbbr, setCommunityAbbr] = useState(null);
    const [selectedCommunityOption, setSelectedCommunityOption] = useState(null);
    const [groupsHash, setGroupsHash] = useState({});
    const [communityId, setCommunityID] = useState(undefined);

    var description;
    var altList;

    const pic = usePic();

    let navigate = useNavigate(); 
    const routeChange = (redirectTo) =>{ 
        navigate(redirectTo);
    }

    function createProviderList(list) {
        var providerPromises = [];
        list.forEach(function(item) {
            if (typeof item === 'object') {
                providerPromises.push(api.GetProvider(item.npi));
            } else {
                providerPromises.push(api.GetProvider(item));
            }
        });
        Promise.all(providerPromises).then(function(providers) {
            setNewCommunityMembers(providers);
            updateCommunityCount(providers);
        });
    }

    function updateCommunityCount (providers) {
        let providersVal;
        if (providers) {
            providersVal = providers
        }
        else {
            providersVal = newCommunityMembers;
        }

        if (communityId) {
            addAndRemoveFlags({id:communityId, providers:providersVal});
            let tempProvidersToAdd = providersVal.filter(function(member) {
                return !groupsHash[communityId].npis.includes(member.npi);
            }).length
            setProvidersToAdd(tempProvidersToAdd);
            setTotalLength(groupsHash[communityId].count + tempProvidersToAdd);
        } else {
            addAndRemoveFlags({providers:providersVal});
            setProvidersToAdd(providersVal.length);
            setTotalLength(providersVal.length);
        }
    };

    function addAndRemoveFlags({id, providers}) {
        providers.forEach(function(member) {
            if (id && groupsHash[id].npis.indexOf(member.npi) > -1) {
                member._repeat = true;
            } else {
                member._repeat = false;
            }
        });
    }

    function determineNpis(existingNpis) {
        var npisToAdd = newCommunityMembers.map(function(provider) {
            return provider.npi;
        });
        
        if (existingNpis) {
            var nonRepeatingNpisToAdd = npisToAdd.filter(function(npi) {
                return !existingNpis.includes(npi);
            });
            npisToAdd = existingNpis.concat(nonRepeatingNpisToAdd);
        }
        
        return npisToAdd.slice(0,500);
    }

    function updateCommunity () {

        if (action == 'Append') {
            api.GetCommunity(communityId)
            .then(function(res) {
                res.npis = determineNpis(res.npis);
                return res.Update({res},{api, pic});
            }).then(function(updatedCommunity) {
                wrapUp(updatedCommunity);
            }, function(err) {
                console.log('error', err);
            });
        } else {
            let community = new api.Community();
            community.npis = determineNpis();
            community.abbr =  communityAbbr || suggestedAbbr;
            community.name = communityName;
            community.Update({community},{api, pic})
            .then(function(updatedCommunity) {
                wrapUp(updatedCommunity);
            }, function(err) {
                console.log('error', err);
            });
        }
    };

    function remove (member) {
        newCommunityMembers.splice(newCommunityMembers.indexOf(member), 1);
        setNewCommunityMembers(newCommunityMembers);
        updateCommunityCount(newCommunityMembers);
    };

    function handleToggleList () {
        setToggleList(!toggleList);
        
        if (toggleList) {
            createProviderList(CommunityList.altList);
        } else {
            createProviderList(CommunityList.list);
        }
    };

    function handleActionChange(e) {
        let val = e.target.value;

        if (val === 'Create') {
            setCommunityID(undefined);
        }

        setAction(val);
        updateCommunityCount();
    };

    function handleCheckboxChange(val,e) {
        let checked = e.target.checked;

        if (val == "community.watching") {
            setCommunityWatching(checked);
        }
        else if (val == "community.isPublic") {
            setCommunityIsPublic(checked);
        }
        else if (val == "community.goHome") {
            setCommunityGoHome(checked);
        }
    }

    function handleCommunityNameChange(e) {
        let val = e.target.value

        setCommunityName(val);

        let tempSuggestedAbbr;

        val = val.split(' ');

        if (val.length > 1) {
           tempSuggestedAbbr = val.reduce(function(acc, curr) { return acc + curr.charAt(0)}, '');
        } else {
            tempSuggestedAbbr = val[0];
        }

        if (tempSuggestedAbbr.length > 0) {
            setSuggestedAbbr(tempSuggestedAbbr.slice(0,3));
            setCommunityAbbr(tempSuggestedAbbr.slice(0,3));
        }
        else {
            setSuggestedAbbr('e.g. ABC');
            setCommunityAbbr(null);
        }
    };

    function handleCommunityAbbrChange(e) {
        let val = e.target.value
        setCommunityAbbr(val);
    }

    function wrapUp(updatedCommunity) {
        let redirectPath = "/community/" + updatedCommunity.id + "/home";
        handleClose();
        if (communityGoHome) {
            routeChange(redirectPath)
        }
    }

    function handleClose() {
        setProvidersToAdd(null);
        setNumber(null);
        setTotalLength(null);
        setSuggestedAbbr('e.g. ABC');
        setNewCommunityMembers([]);
        setOwnedGroups(null);
        setToggleList(false);
        setAction("Create");
        setCommunityWatching(true);
        setCommunityIsPublic(false);
        setCommunityGoHome(false);
        setCommunityName(null);
        setCommunityAbbr(null);
        setSelectedCommunityOption(null);
        setGroupsHash({});
        setCommunityID(undefined);
        onClose();
    }
    
    async function authToken() {
        return await api.options().headers.Authorization.slice(7);
    }

    async function getGroups() {
        let auth = await authToken();

        api.Groups(auth)
        .then(function(groups) {
            var filteredResults = groups.filter(function(group) {
                return group.owned;
            });

            setOwnedGroups(filteredResults.sort((a, b) => (a.name > b.name) ? 1 : -1));
            
            filteredResults.forEach(function(group) {
                groupsHash[group.id] = { 
                    count: group.count,
                    npis: group.npis
                };
            });
        });

    }

    const handleCommunityChange = (selectedOption) => {
        setSelectedCommunityOption(selectedOption);
        setCommunityID(selectedOption.value);
        updateCommunityCount();
        setCommunityName(selectedOption.label);
    };

    const formatOptionLabel = ({ value, label, abbr, count }) => (
        <div >
          <div><p>{label}</p></div>
            <small><p>
                abbr: {abbr} count: {count}
            </p></small>
        </div>
      );
      

    useEffect(() => {

        if (show) {
            getGroups();
            let comm = CommunityList.get();
            description = comm.description;
            altList = comm.altList;
            createProviderList(comm.list);
        }
    }, [show]);

      return (
        <div className="community-modal">
         <Modal show={show} onHide={() => handleClose()}  dialogClassName="modal-lg-community">
            <Modal.Header closeButton>
                <h3 className="modal-title" id="modal-title-search">Create Community</h3>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="row">
                        <div className="col-md-7">
                            <label htmlFor="abbr">Community members to be added: {providersToAdd | number}. Total: {totalLength | number}.</label>
                            {totalLength > 500 ?
                                <div className="row community-warning">
                                    <div className="col-xs-2 icon">
                                        <i className="fa fa-exclamation-triangle fa-2x"></i>
                                    </div>
                                    <div className="col-xs-10 text">
                                        <span className="warning">The maximum size of a community is 500. Only the first 500 members will be added.</span>
                                    </div>
                                </div>
                            : ""}
                            <div className="community-container">
                                <div>
                                    {newCommunityMembers.map((member, index) => {
                                        return (
                                            <div key={'member-' + index + '-' + uuid_v4}>
                                                <span onClick={() => remove(member)} className="delete"><i className="fa fa-minus-circle" aria-hidden="true"></i></span>
                                                {member.npi} - {member.displayName} {member._repeat ? <span className="repeat-warning"><i className="fa fa-exclamation" aria-hidden="true"></i></span> : ""}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {altList && altList.length ?
                            <PopoverDetails providerTooltip={description}>
                                <div className="add-members" onClick={() => handleToggleList()}>
                                    {toggleList ?
                                    <span className="view-full-list">Original List <i className="fa fa-caret-up" aria-hidden="true"></i></span>
                                    :
                                    <span className="view-full-list">Expand List <i className="fa fa-caret-down" aria-hidden="true"></i></span>
                                    }
                                </div>
                            </PopoverDetails>
                            : ""}
                        </div>
                        
                        <div className="col-md-5">
                    
                            <div className="form-group radio-com">
                                <div className="toggle_radio tourscript-input-type">
                                    <input type="radio" className="toggle_option" id="create_toggle" name="create_toggle" value="Create" checked={action == "Create"} onChange={(e) => handleActionChange(e)}/>
                                    <input type="radio" className="toggle_option" id="append_toggle" name="append_toggle" value="Append" checked={action == "Append"} onChange={(e) => handleActionChange(e)}/>
                                    <label htmlFor="create_toggle"><p>Create</p></label>
                                    <label htmlFor="append_toggle"><p>Add</p></label>
                                    <div className="toggle_option_slider"></div>
                                </div>
                            </div>
                            
                            {action == "Create" ?
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="name">Community Name</label>
                                        <input type="text" className="form-control" placeholder="e.g. Employed Physicians" name="name" onChange={(e) => handleCommunityNameChange(e)} value={communityName ? communityName : ""} required></input>
                                        <span className="formMsg">This is a required field.</span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="abbr">Community Abbreviation</label>
                                        <input type="text" className="form-control" placeholder={suggestedAbbr} name="abbr" onChange={(e) => handleCommunityAbbrChange(e)} value={communityAbbr ? communityAbbr : ""} maxLength={3}></input>
                                        <span className="formMsg">Maximum 3 characters.</span>
                                    </div>
                                </div>
                            : ""}

                            {action == "Append" ?
                                <div>
                                    <Select
                                        className="community-select"
                                        value={selectedCommunityOption}
                                        onChange={handleCommunityChange}
                                        formatOptionLabel={formatOptionLabel}
                                        options={ownedGroups.map(comm => {
                                            return ({label: comm.name, value: comm.id, abbr: comm.abbr, count: comm.count});
                                        })}
                                        placeholder="Please choose or search for a community..."
                                        required
                                    />
                                    <span className="formMsg">This is a required field.</span>
                                </div>
                            : ""}
                            
                            <div className="form-group">
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" checked={communityWatching} onChange={(e) => handleCheckboxChange("community.watching",e)}/> <strong>Watch:</strong> Identify members of this Community in Visualizations and Reports.
                                    </label>
                                </div>
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" checked={communityIsPublic} onChange={(e) => handleCheckboxChange("community.isPublic",e)}/> <strong>Share:</strong> Allow other members in your Account to view and Watch this Community.
                                    </label>
                                </div>
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" checked={communityGoHome} onChange={(e) => handleCheckboxChange("community.goHome",e)}/>Go to this Community's Home Page after creating.
                                    </label>
                                </div>
                            </div>
                            {action == "Append" ?
                                <div className="repeat-message">
                                    <span className="repeat-warning"><i className="fa fa-exclamation" aria-hidden="true"></i></span> Indicates Provider is already in the selected Community and will not be added.
                                </div>
                            : ""}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="modal-footer">
                    <a href="/search" onClick={() => handleClose()} className="back-button"><i className="fa fa-arrow-left" aria-hidden="true"></i> Build New Community</a>
                        <button className="btn btn-primary" type="submit" onClick={() => updateCommunity()} disabled={!communityName}>
                            {action == "Create" ?
                                <span>Create</span>
                            : ""}
                            {action == "Append" ?
                                <span>Add</span>
                            : ""}
                        </button>
                        <button className="btn btn-warning" type="button" onClick={() => handleClose()}>Cancel</button>
                </div>
            </Modal.Footer>
          </Modal>
        </div>
      )
}
    
