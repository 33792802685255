import React, { useEffect, useState, useRef } from 'react'
import { useRouteLoaderData, Link } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent'
import { PopoverDetails }  from '../../components/popoverDetails/popoverDetails';
import { PopoverMenu }  from '../../components/popoverMenu/popoverMenu';


import { useConfig, useApi, usePic, useTourManager, useLogging } from '../../services/servicesContext';

export function SharedVisitsComponent({ opts, notify, download, tour, sharePhTableData, CommunityList }) {

    const apiCopy = useApi();
    const picCopy = usePic();
    const tourManager = useTourManager();
    const logging = useLogging();
    const tableRef = useRef(null);

    const [ data, setData ] = useState(null);
    const [ loading, setLoading ] = useState(null);
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    
    useEffect(() => {

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.reports.sharedvisits`
        });
        
        async function init() {
            try {
                setLoading(true);
                
                tourManager.createTour([
                    {
                        id: 1,
                        title: 'Shared Visits Report - 1/12',
                        text: 'This report shows where patients end up within 30 days before/after an acute care episode, helping you understand where mutually beneficial relationships with pre- and post-acute care facilities and physicians can be developed.<br><br>Use left/right arrow keys to navigate.',
                    },
                    {
                        id: 2,
                        attachTo: { element: '.tourscript-name', on: 'bottom'},
                        title: 'Shared Visits Report - 2/12',
                        text: 'For example, this provider saw...',
                    },
                    {
                        id: 3,
                        attachTo: { element: '.tourscript-uniquefrom', on: 'bottom'},
                        title: 'Shared Visits Report - 3/12',
                        text: 'this many patients...',
                    },
                    {
                        id: 4,
                        title: 'Shared Visits Report - 4/12',
                        text: `after ${npis.name.display}`,
                    },
                    {
                        id: 5,
                        attachTo: { element: '.tourscript-sharedfrom', on: 'bottom'},
                        title: 'Shared Visits Report - 5/12',
                        text: `which equaled this many total visits...`,
                    },
                    {
                        id: 6,
                        attachTo: { element: '.tourscript-sameday', on: 'bottom'},
                        title: 'Shared Visits Report - 6/12',
                        text: 'and this many same day visits.',
                    },
                    {
                        id: 7,
                        attachTo: { element: '.tourscript-uniqueto', on: 'bottom'},
                        title: 'Shared Visits Report - 7/12',
                        text: 'And this many patients...',
                    },
                    {
                        id: 8,
                        title: 'Shared Visits Report - 8/12',
                        text: `before ${npis.name.display}`,
                    },
                    {
                        id: 9,
                        attachTo: { element: '.tourscript-sharedto', on: 'bottom'},
                        title: 'Shared Visits Report - 9/12',
                        text: 'which equaled this many total visits...',
                    },
                    {
                        id: 10,
                        attachTo: { element: '.content-container thead', on: 'bottom'},
                        title: 'Shared Visits Report - 10/12',
                        text: 'You can sort on any column by simply clicking on the header name.',
                    },
                    {
                        id: 11,
                        attachTo: { element: '.content-container .table-controls-input .filter-box', on: 'bottom'},
                        title: 'Shared Visits Report - 11/12',
                        text: 'Also, you can filter results here by searching for any value in the row.',
                    },
                    {
                        id: 12,
                        attachTo: { element: '.content-container .table-controls-input #tourscript-buttons', on: 'bottom'},
                        title: 'Shared Visits Report - 12/12',
                        text: 'Use this button to download the data in the table.',
                    }
                ]);
                
                let data = await apiCopy.Relations(npis.npi);
                
                if (data.length === 0) {
                    console.log('No relations found for provider: ', data);
                    setLoading(false)
                    notify.alert({
                        title: 'NO DATA',
                        text: 'No data found for provider.',
                        delay: 30000
                    });
                }
                
                var hash = data.reduce(function(hash, rel) {
                    var dir = rel.source.npi == npis.npi ? 'target' : 'source';
                    var npi = rel[dir].npi;
                    if ( !hash[npi] ) {
                        hash[npi] = {
                            [dir]: Object.assign({}, rel.values)
                        };
                    } else {
                        hash[npi][dir] = Object.assign({}, rel.values);
                    }
                    hash[npi].sameday = hash[npi].sameday || rel.values.sameday;
                    return hash;
                }, {});

                var datums = Object.keys(hash).map(function(npi) {
                    return hash[npi];
                });
                
                var nullSubstitute = {
                    sameday: 0,
                    shared: 0,
                    unique: 0
                };
                
                datums = datums.map(function(datum) {
                    if (datum.target && !datum.source) {
                        datum.source = Object.assign({}, nullSubstitute);
                    } else if (datum.source && !datum.target) {
                        datum.target = Object.assign({}, nullSubstitute);
                    }
                    return datum;
                });

                var promises = Object.keys(hash).map(function(npi) { return apiCopy.GetProvider(npi); });
                
                Promise.all(promises).then(function(providers) {
                    providers.forEach(function(provider) {
                        hash[provider.npi].provider = provider; 
                    });
                      datums = datums.map(function(datum) {
                          datum.communities = picCopy(datum.provider.npi);
                          return datum;
                      });

                      const data = datums.map((datum) => {
                          return {
                                "entitytype" : datum.provider.entitytype,
                                "phone" : datum.provider.location.phone_number,
                                "taxonomy_code": datum.provider.taxonomy.code,
                                "address" : datum.provider.location.address,
                                "city" : datum.provider.location.city,
                                "state" : datum.provider.location.state,
                                "county" : datum.provider.location.county,
                                "zip" : datum.provider.location.postal_code,
                                "npi" : datum.provider.npi,
                                "communities" : datum.communities,
                                "provider" :  datum.provider.name.display,
                                "specialty" : datum.provider.taxonomy.classification + (datum.provider.taxonomy.specialization ? " (" + datum.provider.taxonomy.specialization + ")" : ""),
                                "shared_visits_from": datum.target.shared,
                                "unique_patients_from": datum.target.unique,
                                "sameday": datum.sameday,
                                "unique_patients_to": datum.source.unique,
                                "shared_visits_to": datum.source.shared
                          }
                        })
                      setLoading(false);
                      setData(data);

                }).catch(err => console.error(err));

            } catch(err) {
                setLoading(false);
                notify.error({text: 'Error requesting relations'});
                console.error('error requesting relations', err);
            }
        }

        init();
        return () => {
            tourManager.clearTour();
        };
    }, [npis]);

  const reportConfig = function() { 
        return {  
          tableConfig :  [
            {
                "header": {
                    "id": "col16",
                    "accessor": "entitytype",
                    "content": "Entity Type",
                    "headerTooltip": "Row provider entity type",
                    "defaultVisibilty": false,
                    "export": false,
                    "sortable": true,
                    "template": content => content && ((content === '2') ? 'Organization' : 'Physician' )
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 16
                    }
                },

                {
                    "header": {
                        "id": "col17",
                        "accessor": "taxonomy_code",
                        "content": "Taxonomy Code",
                        "headerTooltip": "Row provider taxonomy code",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 17
                        }
                },
                {
                    "header": {
                        "id": "col15",
                        "accessor": "address",
                        "content": "Address",
                        "headerTooltip": "Row provider address",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 15
                        }
                },
                {
                    "header": {
                        "id": "col14",
                        "accessor": "city",
                        "content": "City",
                        "headerTooltip": "Row provider city",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 14
                        }
                },
                {
                    "header": {
                        "id": "col13",
                        "accessor": "state",
                        "content": "State",
                        "headerTooltip": "Row provider state",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 13
                        }
                },
                {
                    "header": {
                        "id": "col12",
                        "accessor": "county",
                        "content": "County",
                        "headerTooltip": "Row provider county",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 12
                        }
                },
                {
                    "header": {
                        "id": "col10",
                        "accessor": "zip",
                        "content": "ZIP",
                        "headerTooltip": "Row provider postal code",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 10
                        }
                },
                {
                    "header": {
                        "id": "col18",
                        "accessor": "phone",
                        "content": "Phone",
                        "headerTooltip": "Row provider phone number",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": function(content) { const formatPhoneNum = content.toString();
                            return <span className="text-nowrap">({formatPhoneNum.substring(0, 3)}) {formatPhoneNum.substring(3, 6)}-{formatPhoneNum.substring(6, 10)}</span> 
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 18
                        }
                },
                {
                    "header": {
                        "id": "col1",
                        "accessor": "npi",
                        "content": "NPI",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        'tooltipType': 'details',
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowDataObject) { 
                            const formatPhoneNum = rowDataObject && rowDataObject.phone && rowDataObject.phone.toString() || '';
                            const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            const provider = { 
                                location : {
                                  'postal_code' : rowDataObject.zip,
                                  'phone_number': phone,
                                  'city': rowDataObject.city,
                                  'state': rowDataObject.state
                                 },
                                'name': {'display' : rowDataObject.provider }
                              } 
                            return <PopoverDetails provider={provider}><Link to={`/${rowDataObject.entitytype == 1 ? 'physician' : 'organization'}/${content}/home`}>{content}</Link></PopoverDetails>  
                        }  
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 1                    
                    }
                },
                {
                    "header": {
                        "id": "col2",
                        "accessor": "communities",
                        "content": "Communities",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        "export": true,
                        "sortable": true,
                        "template": function(content) {    
                            if (content && content.length === 0) return                   
                            const arrContent = content.map((item) => {
                                const { name, insert_date, owner, npis } = item;
                                const providerCollection = {
                                    name: name || "",
                                    insert_date: insert_date && new Date(Number(insert_date)).toDateString() || "",
                                    insert_user: owner || "",
                                    npis: npis || ""
                                }
                                return (<PopoverDetails providerCollection={providerCollection}><Link to={`/community/${item.id}/home`}>{item.abbr} </Link></PopoverDetails>)  
                            }) 
                            return arrContent
                       }   
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 2                    
                    }
                },
                {
                    "header": {
                        "id": "col3",
                        "accessor": "provider",
                        "content": "Provider",
                       "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowObject) {
                            const provider = {  name : content, npi : rowObject.npi, entitytype: rowObject.entitytype };
                            return <>
                                <PopoverMenu provider={provider}>
                                    <span className='tourscript-name'>{content}</span>
                                    <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </span>
                                </PopoverMenu>
                            </>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                           "content": {},
                            "style": "",
                            "id": 3
                    }
                },
                {
                    "header": {
                        "id": "col4",
                        "accessor": "specialty",
                        "content": "Specialty",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                },
                {
                    "header": {
                        "id": "col5",
                        "accessor": "shared_visits_from",
                        "content": "Shared Visits From",
                        "headerTooltip": "Total visits to the row provider from report provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content && <span className='tourscript-sharedfrom'>{content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                        }
                },
                {
                    "header": {
                        "id": "col6",
                        "accessor": "unique_patients_from",
                        "content": "Unique Patients From",
                        "headerTooltip": "Unique patients to the Provider from the report provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content && <span className='tourscript-uniquefrom'>{content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                },
                {
                    "header": {
                        "id": "col7",
                        "accessor": "sameday",
                        "content": "Same Day Visits",
                        "headerTooltip": "Same day visits for the Provider and report provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content && <span className='tourscript-sameday'>{content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 7
                    }
                },   
                {
                    "header": {
                        "id": "col8",
                        "accessor": "unique_patients_to",
                        "content": "Unique Patients To",
                        "headerTooltip": "Unique patients from the Provider to the report provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "sort": 'desc',
                        "template": content => <span className='tourscript-uniqueto'>{content && content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 8
                        }
                },
                {
                    "header": {
                        "id": "col9",
                        "accessor": "shared_visits_to",
                        "content": "Shared Visits To",
                        "headerTooltip": "Total visits from the Provider to report provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content && <span className='tourscript-sharedto'>{content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 9
                        }
                   }
               ],
        configure : true,
        configureButton: true,
        showSelected: false,
        filterInput: true,
        csvdownload: true,
        pagination: true,
        reportTitle: `Referrals Report for ${npis && npis.name && npis.name.display}`,
        localStorageName: 'app.phy.reports.sharedvisits.v2'
        }
    }

  
const updateCommunityList = (currentList) => {
    const fullList = data || [];
    if (currentList.length < fullList.length) {
        CommunityList.update({listVal: currentList, altListVal: fullList});
    } else {
        CommunityList.update({listVal: currentList});
    }
}

  return (
    <>
    <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
    { (data) ? <PhtableComponent data={data} reportconfig={reportConfig} download={download} needCommUpdate={true} CommunityList={CommunityList}/>  : null}
    </>
  )
}
